import Swiper, { Navigation, Mousewheel } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const slider = () => {
  var slider = new Swiper('.team-slider', {
    slidesPerView: 1.6,
    spaceBetween: 10,
    mousewheel: true,

    navigation: {
      nextEl: '.about-us-block__buttons--next',
      prevEl: '.about-us-block__buttons--prev',
    },
    modules: [Navigation, Mousewheel],
  });
};

let spv = 3;

if (window.innerWidth < 768) {
  spv = 1;
}
if (window.innerWidth > 768) {
  spv = 2;
}
if (window.innerWidth > 1200) {
  spv = 3;
}

export const reviewSlider = () => {
  var slider = new Swiper('.reviews-block__reviews', {
    slidesPerView: spv,
    spaceBetween: 24,
    mousewheel: true,

    navigation: {
      nextEl: '.reviews-block__button-next',
      prevEl: '.reviews-block__button-prev',
    },
    modules: [Navigation, Mousewheel],
  });
};
