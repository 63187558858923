export class ServiceDropdown {
  constructor() {
    this.toggleServiceDropdown();
  }

  toggleServiceDropdown() {
    const services = [...document.querySelectorAll('.services-block__dropdown--container')];

    if (services.length > 0) {
      services.forEach((service) => {
        service.addEventListener('click', (e) => {
          const customEvent = new CustomEvent('servicesClick');
          document.dispatchEvent(customEvent);
          if (e.target.classList.contains('is-open')) {
            e.target.classList.remove('is-open');
            e.target.nextElementSibling.classList.remove('is-open');
            return;
          }
          services.forEach((service) => {
            service.classList.remove('is-open');
            service.nextElementSibling.classList.remove('is-open');
          });

          e.target.classList.add('is-open');
          e.target.nextElementSibling.classList.add('is-open');
        });
      });
    }
  }
}
