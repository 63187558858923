'use strict';

import { slider, reviewSlider } from './includes/slider';
import { vertical } from './includes/vertical';
import { loco } from './includes/loco';
import { ServiceDropdown } from './includes/services';

document.addEventListener('DOMContentLoaded', () => {
  slider();
  vertical();
  loco();
  mobileMenu();
  reviewSlider();
  new ServiceDropdown();

  window.addEventListener('scroll', () => {
    headerBg();
  });
  headerBg();
});

const headerBg = () => {
  const header = document.querySelector('header.header');
  if (window.scrollY > 100 && !header.classList.contains('alt')) {
    header.classList.add('dark-bg');
  } else {
    header.classList.remove('dark-bg');
  }
};

const mobileMenu = () => {
  const mobileToggle = document.querySelector('button.header__toggle');
  const mobileMenu = document.querySelector('.mobile-menu');
  const header = document.querySelector('header.header');
  mobileToggle.addEventListener('click', () => {
    document.body.classList.toggle('fixed');
    mobileMenu.classList.toggle('is-open');
    header.classList.toggle('is-open');
  });
  if (window.innerWidth < 768) {
    document.querySelectorAll('a').forEach((anchor) => {
      if (anchor.hash) {
        anchor.addEventListener('click', () => {
          document.body.classList.toggle('fixed');
          mobileMenu.classList.toggle('is-open');
          header.classList.toggle('is-open');
        });
      }
    });
  }
};
