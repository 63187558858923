import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

let scrolltrigger;

const initScrollTrigger = () => {
  const pitchBlocks = [...document.querySelectorAll('.pitch-block')];
  pitchBlocks.forEach((panel, index) => {
    if (index != pitchBlocks.length - 1) {
      scrolltrigger = ScrollTrigger.create({
        trigger: panel,
        start: 'top',
        pin: true,
        pinSpacing: false,
      });
    }
  });
};

export const vertical = () => {
  initScrollTrigger();
  document.addEventListener('servicesClick', () => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 750);
  });
};
