import LocomotiveScroll from 'locomotive-scroll';
import { gsap, ScrollTrigger } from 'gsap';

export const loco = () => {
  //   var scroll = new LocomotiveScroll({
  //     el: document.querySelector('body'),
  //     smooth: true,
  //     smoothMobile: true,
  //     direction: 'horizontal',
  //     inertia: 1,
  //     repeat: true,
  //     offset: ['10%', 100],
  //   });
  //   gsap.registerPlugin(ScrollTrigger);
  //   ScrollTrigger.defaults({
  //     toggleActions: 'restart pause resume pause',
  //   });
  //   gsap.to('.first', {
  //     scrollTrigger: '.first',
  //     pin: '.first',
  //     scrub: 0.5,
  //     start: 'top top',
  //     end: 'bottom 100px',
  //   });
};
